import { Box, Typography } from "@mui/material";
import useBreakpoint from "../../../hooks/use-breakpoint";

type ParagraphProps = {
  title: string;
  text: string;
};

const Paragraph = ({ title, text }: ParagraphProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography sx={{ fontSize: 21, fontFamily: "Comfortaa" }}>
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: 16,
          fontFamily: "Open Sans",
          width: { xs: "100%", md: 280 },
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export const Pillars = () => {
  const isMobile = useBreakpoint();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexWrap: { xs: "wrap", md: "inherit" },
        alignItems: "center",
        justifyContent: "center",
        gap: { xs: 5, md: 10 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { sm: "auto", xl: "30rem" },
          gap: { xs: 3, md: 5 },
        }}
      >
        <Typography
          sx={{ fontSize: 32, fontFamily: "Comfortaa", color: "#212121" }}
        >
          Four Pillars of Agriculture
        </Typography>
        <Paragraph
          title="1. Land Evaluation"
          text="Assess and value land assets to optimize regen outputs"
        />
        <Paragraph
          title="2. Project Development"
          text="Efficient planning and organizing with experts and suppliers, farmers and Investors to design profitable intervention"
        />
        <Paragraph
          title="3. Farm Operations"
          text="Managing on-the-ground teams with best of practice and real time support"
        />
        <Paragraph
          title="4. Funding Solutions "
          text="Diverse access to premium impact funding solutions and credits"
        />
      </Box>
      <img
        alt="some"
        src="/pillars.png"
        style={{
          width: "100%",
          height: "auto",
          objectFit: "cover",
          ...(!isMobile && {
            width: "auto",
            height: "auto",
          }),
        }}
      />
    </Box>
  );
};
