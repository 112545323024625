import { TeamMember } from "../types/about";

export const members: TeamMember[] = [
  { img: "lachlan.png", name: "Lachlan Graham", title: "CEO Fresh Earth Ag" },
  {
    img: "lukemp.png",
    name: "Luke Makepeace",
    title: "Co-Founder Fresh Earth Universe",
  },
  { img: "lukestar.png", name: "Luke Star", title: "CFO Fresh Earth Ag" },
  { img: "ken.png", name: "Ken Chan", title: "Super Majors Fresh Earth Ag" },
  { img: "andrew.png", name: "Andrew Tracy", title: "CEO Fresh Earth" },
  { img: "ben.png", name: "Ben Wills", title: "Legal" },
  { img: "bob.png", name: "Bob Bell", title: "Finance" },
  {
    img: "bennott.png",
    name: "Ben Nott",
    title: "Co-Founder & CMO Fresh Earth Universe",
  },
  {
    img: "shana.png",
    name: "Shana Vida Gaveron",
    title: "Biodiversity Advisor",
  },
  {
    img: "simon.png",
    name: "Simon Skillicorn",
    title: "Strategic Partnerships Manager",
  },
  {
    img: "paul.png",
    name: "Dr. Paul Taylor PhD",
    title: "Co-Founder & Chief Scientist Head of Biochar",
  },
  {
    img: "russell.png",
    name: "Russell Rankin",
    title: "Government Industry Expert",
  },
];
