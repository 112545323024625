import { Box, Typography } from "@mui/material";
import useBreakpoint from "../../../hooks/use-breakpoint";

export const AboutUs = () => {
  const isMobile = useBreakpoint();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, md: 5 },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "32px", md: "48px" },
          fontWeight: { xs: 500, md: 700 },
          fontFamily: "Comfortaa",
        }}
      >
        About Us
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          fontFamily: "Open Sans",
          width: { xs: "100%", xl: 1250 },
        }}
      >
        Fresh Earth is a cross-disciplinary team—agriculture, finance,
        operations, AI, technology, environmental accounting, and systems
        thinking—united in creating a profitable new asset class built on
        regenerative land management. We manage these assets indefinitely,
        maximizing impact at minimal cost and distributing value transparently
        to both retail and wholesale investors. Our unwavering commitment to
        transparency and immutable systems ensures trust, integrity, and
        sustained environmental and economic benefits.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "inherit" },
          gap: { xs: 5, md: 10 },
          alignItems: "center",
        }}
      >
        <img
          alt="about"
          src="/about-us.png"
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            ...(!isMobile && {
              width: "auto",
              height: "auto",
            }),
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: { xs: "auto", xl: "587px" },
          }}
        >
          <Typography
            sx={{ fontSize: "21px", fontWeight: 500, fontFamily: "Comfortaa" }}
          >
            Regenerative agriculture accelerates when four stakeholders align
          </Typography>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 400, fontFamily: "Open Sans" }}
          >
            We unify four key players—experts, suppliers, farm operators, and
            investors—onto a single platform, rapidly scaling regenerative
            agriculture. 
          </Typography>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 400, fontFamily: "Open Sans" }}
          >
            Our approach follows four steps: <br /> 1. identify suitable land,
            <br /> 2. craft science-driven plans,
            <br /> 3. implement them efficiently,
            <br /> 4. and secure investment to fund their impact.
          </Typography>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 400, fontFamily: "Open Sans" }}
          >
            The result: higher returns, greater biodiversity, verified carbon
            credits, and accelerated, cost-effective regeneration. Each
            stakeholder acts within a guided, digital environment, ensuring
            clarity, compliance, and measurable progress at every stage.
          </Typography>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 400, fontFamily: "Open Sans" }}
          >
            It’s the simplest path to sustainable outcomes—maximum impact,
            minimum cost, lightning speed.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
