import { Box } from "@mui/material";
import useBreakpoint from "../../hooks/use-breakpoint";
import { AboutUs } from "./content/about-us";
import { ImpactAndVision } from "./content/impact-vision";
import { Pillars } from "./content/pillars";
import { Retail } from "./content/retail";
import { Team } from "./content/team";

type Props = {
  prevStepCallback: () => void;
};

export const About = ({ prevStepCallback }: Props) => {
  const isMobile = useBreakpoint("md");

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "80%" },
        height: { xs: "100%", md: "80%" },
        backgroundColor: "white",
        borderRadius: { xs: "0px", md: "100px" },
        p: { xs: "12px", md: "64px" },
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: { xs: "100vh", md: "75vh" },
          gap: 25,
          flexDirection: "column",
          alignItems: "center",
          overflowY: "auto",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            position: { xs: "fixed", md: "absolute" },
            top: { xs: 0, md: 50 },
            right: { xs: 0, md: 50 },
            zIndex: 1000,
            cursor: "pointer",
            bgcolor: { xs: "white", md: "auto" },
            width: { xs: "100%", md: "auto" },
            display: "flex",
            justifyContent: "flex-end",
            p: { xs: 2, md: 0 },
          }}
          onClick={prevStepCallback}
        >
          <img src="/close-btn.svg" alt="close" />
        </Box>
        {!isMobile && (
          <img
            src="/logo-full.png"
            alt="logo"
            style={{
              position: "absolute",
              bottom: 30,
              right: 70,
            }}
          />
        )}
        <Box
          sx={{
            pt: { xs: 10, md: 0 },
            display: { xs: "flex", md: "inherit" },
            flexDirection: { xs: "column", md: "inherit" },
            gap: { xs: 10, md: "inherit" },
          }}
        >
          <AboutUs />
          <ImpactAndVision />
          <Pillars />
          <Retail />
          <Team />
        </Box>
      </Box>
    </Box>
  );
};
