import { Box, Typography } from "@mui/material";
import useBreakpoint from "../../../hooks/use-breakpoint";

export const Retail = () => {
  const isMobile = useBreakpoint();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: { xs: 500, md: 700 },
          fontFamily: "Comfortaa",
        }}
      >
        Regenerative Asset Class
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "inherit" },
          alignItems: "center",
          justifyContent: "center",
          gap: { xs: 5, md: 10 },
        }}
      >
        <img
          alt="retail"
          src="/retail.png"
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            ...(!isMobile && {
              width: "auto",
              height: "auto",
            }),
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: { xs: "auto", xl: "587px" },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                fontFamily: "Comfortaa",
              }}
            >
              Regenerative agriculture accelerates when four stakeholders align
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Open Sans",
              }}
            >
              Fresh Earth Regen Land introduces a new regenerative asset
              class—land managed under a 100-year mandate to ensure enduring
              environmental and economic value. Investors can access as little
              as one square meter or scale up to entire farms, all meticulously
              planned, scientifically managed, and set to deliver measurable
              returns.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                fontFamily: "Comfortaa",
              }}
            >
              Key Elements for Investors:
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Open Sans",
              }}
            >
              • Long-Term Stability: Land holdings are secured and managed over
              a century, ensuring enduring regenerative impact and steady value
              appreciation. <br />
              <br />• Diverse Yield Streams: Returns encompass environmental
              credits (e.g., carbon, biodiversity), premium nutrient-rich
              produce, and an increasing net asset value tied to land health.
              <br />
              <br />• Liquidity & Flexibility: Investors can enter and exit at
              defined intervals, facilitating liquidity events that reflect both
              market conditions and ecological performance. <br />
              <br />• Data-Driven Transparency: Every environmental outcome,
              operational cost, and revenue source is tracked and verified,
              allowing investors to assess performance and align with their
              impact goals. <br />• Global Scalability: A consistent framework
              enables efficient replication
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Open Sans",
              }}
            >
              By combining robust data, scientific rigor, and market-driven
              incentives, this regenerative asset class offers investors a
              transparent, scalable, and profitable path toward environmental
              restoration—maximizing impact while minimizing cost and
              complexity.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
